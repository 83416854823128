// Vendor
import 'app/../publicPathFix.js';
import { createRoot } from 'react-dom/client';
import { css } from '@emotion/css';
import { Provider } from 'react-redux';

// Local
import { cmsURLs } from 'app/constants/urls';

import breakpoints from 'styles/constants/breakpoints.js';
import typographyStyles from 'styles/themes/waymark/typography.js';
import { whiteColor, proBlackColor } from 'styles/themes/waymark/colors.js';

import spaceWombatImage from '../../legacy/images/core/404/404_space_wombat.jpg';
import store from 'app/state/store.js';

// Import the app theme store to ensure the theme is applied
import 'app/state/appThemeStore/appThemeStore';

/**
 * Defines a fallback page that loads outside of the context of the Waymark
 * store when an incorrect URL is entered.
 */
const PageNotFound = () => (
  <div
    className={css`
      background-color: #060606;
      min-height: 100vh;
      width: 100%;
      background-image: url(${spaceWombatImage});
      background-repeat: no-repeat;

      background-position: center center;
      background-size: cover;
    `}
  >
    <section
      className={css`
        min-height: 90vh;
        position: relative;
      `}
    >
      <div
        className={css`
          position: absolute;
          top: 45%;
          left: 50%;
          transform: translate(-50%, -50%);

          @media ${breakpoints.medium.queryUp} {
            width: 40%;
          }
        `}
      >
        <h1
          className={css`
            ${typographyStyles.heroResponsive}
            color: ${whiteColor};
            text-align: center;
            margin-bottom: 1.5rem;
          `}
        >
          Page Not Found
        </h1>
        <a
          href={cmsURLs.home}
          className={css`
            ${typographyStyles.button}

            color: ${proBlackColor};
            background-color: ${whiteColor};
            border: 1px #f2f2f2 solid;

            display: block;
            text-decoration: none;
            text-transform: uppercase;

            text-align: center;
            max-width: 300px;

            margin: 0 auto;
            padding: 16px 24px;
            border-radius: 3px;
          `}
        >
          Go Home
        </a>
      </div>
    </section>
  </div>
);

const container = document.querySelector('[data-javascript-only-container]');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <PageNotFound />
  </Provider>,
);
